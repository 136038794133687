<!-- 商学院 -->
<template>
  <div>
    <!-- <div class="eg_hwef">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="公司动态" name="1"></el-tab-pane>
        <el-tab-pane label="市场动态" name="2"></el-tab-pane>
        <el-tab-pane label="媒体动态" name="3"></el-tab-pane>
      </el-tabs>
    </div> -->
    <div class="ef_bhygywef">
      <div v-for="(item, index) in list" :key="index" class="fewrf_huhwe">
        <img :src="item.img" alt="" />
        <div class="erg_hgwf">
          {{ item.title }}
        </div>
        <div class="erg_hnwd">{{ item.time }}</div>
        <div class="erg_hnwd">
          {{ item.dev }}
        </div>
      </div>
    </div>
    <!-- <div class="eg_hgywf">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      activeName: "1",
      list: [
        {
          img: require("../../../image/yaohaoyou.png"),
          title: "邀好友，赚赏金。邀请20个好友即可提现200元现金，多邀多得。",
          time: "2020-11-23",
          dev: "车世际最新活动邀请20个好友，即可提现200元现金。最近的毛不多，赶紧动员身边的朋友一起薅羊毛吧！",
        },
        {
          img: require("../../../image/xingbanbenshang.png"),
          title: "车世际新版本上线，隆重推出车辆托管以及网约车司机招募2大特色功能",
          time: "2020-12-02",
          dev: "和车世际合作，车辆托管业务最高返佣1500元，网约车司机招募最高返佣2000元。",
        },
        {
          img: require("../../../image/xicheimg.png"),
          title: "车世际联合平台商家驰行汽车推出1元洗车活动！",
          time: "2020-12-02",
          dev: "2021年12月09日-2021年12月12日，在雅安万达广场旁的一家洗车店人声鼎沸，络绎不绝，好事连连。",
        },
       
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleClick() {},
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
};
</script>
<style scoped>
/* @import url(); 引入css类 */
.eg_hwef {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  border-bottom: 1px solid #d5d5d5;
}
.eg_hwef /deep/.el-tabs__header {
  margin: 0;
}
.eg_hwef /deep/.el-tabs__item.is-active {
  color: #ff6600;
}
.eg_hwef /deep/.el-tabs__active-bar {
  background-color: #ff6600;
}
.eg_hwef /deep/.el-tabs__item:hover {
  color: #ff6600;
  cursor: pointer;
}
.ef_bhygywef {
  width: 100%;
  padding: 53px 240px 0 240px;
  display: flex;
  flex-wrap: wrap;
}

.eg_hgywf {
  display: flex;
  justify-content: center;
}
.eg_hgywf
  /deep/.el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #ff6600;
  color: #fff;
}
.eg_hgywf
  /deep/.el-pagination.is-background
  .el-pager
  li:not(.disabled).active:hover {
  color: #fff;
}
.eg_hgywf /deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff6600;
}
.erg_hgwf {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 36px;
  color: #333333;
  padding: 40px 0 30px 0;
  box-sizing: border-box;
}
.erg_hnwd {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 27px;
  color: #333333;
}
.fewrf_huhwe {
  width: 30%;
  height: 487px;
  margin-bottom: 60px;
  margin-right: 60px;
  cursor: pointer;
  border: 4px solid #fff;
}
.fewrf_huhwe:nth-child(3n) {
  margin-right: 0px;
}
.fewrf_huhwe:hover {
  border: 4px solid #ff6600;
}
.fewrf_huhwe:hover>.erg_hgwf {
  color: #ff6600;
}
</style>